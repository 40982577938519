var keydown_timeout;
var keydown_xhr;
var uploadedInitiated = false;


var jsFormId = '#form_fast_application';
var jsTheme = 'off';


function refreshInputmask_form_fast_application() {
    $( jsFormId + ' [data-inputmask]' ).inputmask();
}

function autoresizeTextarea_form_fast_application() {
    $( jsFormId ).find( '[data-autoresize]' ).each( function () {
        var offset = this.offsetHeight - this.clientHeight;
        var resizeTextarea = function ( el ) {
            $( el ).css( 'height', 'auto' ).css( 'height', el.scrollHeight + offset );
        };
        $( this ).on( 'keyup input', function () {
            resizeTextarea( this );
        } ).removeAttr( 'data-autoresize' );
        resizeTextarea( this );
    } );
}


function getHash() {
    var hash = window.location.hash;
    return hash.substring(2); // remove #!
}


function confirm_overwrite(message, callback) {
    var c = confirm(message);
    if (c) {
        callback();
    }
}

function confirmation(callback, id, message) {
    var c = confirm(message);
    if (c) {
        callback(id);
    }
}

function scroll2element(el) {
    var position = $(el).offset();
    var $padding = $body.css('paddingTop').replace('px','');
    var y = position.top - 200;
    $('html, body').stop().animate({scrollTop: y}, 'slow');
}


$(function(){

    /*$.validator.setDefaults({
        debug: false,
        errorPlacement: function(error, element) {
            error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().append('<span class=" form-control-feedback"><i class="fa fa-times"></i></span>');
            $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().removeClass('has-error has-feedback');
        }
    });*/
});


function limitText(limitField, limitCount, limitNum) {
    if (limitField.value.length > limitNum) {
        limitField.value = limitField.value.substring(0, limitNum);
    } else {
        limitCount.html(limitNum - limitField.value.length);
    }
}

function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
function checkEmail(emailAddress) {
    var reValidEmail = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");

    return reValidEmail.test(emailAddress);
}