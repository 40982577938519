function initContactForm() {

    $('.contact-form').validate({
        submitHandler: function() {
            var data = $('.contact-form').serialize();
            var object = {
                'success': function (response){
                    $('.contact-form .form-response').removeClass('alert-success');
                    $('.contact-form .form-response').removeClass('alert-danger');

                    if(response['status']=='ok'){
                        form_submit_response('.contact-form', 'success', response['message']);
                         setTimeout(function () {
                            window.location.reload();
                          }, 2000);
                    }else{
                         form_submit_response('.contact-form', 'error', response['message']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

