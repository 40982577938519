function initCareersForm() {
    "use strict";
    
    
     var uploader = new plupload.Uploader({
        runtimes : 'html5,flash,silverlight,html4',

        browse_button : 'pickfiles',
        container: document.getElementById('upload-container'),

        url : "/?module=medialibrary&action=upload",

        filters : {
            max_file_size : '7000kb',
            mime_types: [
                {title : "Documents", extensions : "pdf,doc,docx,txt,rtf"}
            ]
        },

        multi_selection:false,
        unique_names:true,
        init: {
            PostInit: function() {
                document.getElementById('filelist').innerHTML = '';
            },

            FilesAdded: function(up, files) {

                plupload.each(files, function(file) {
                    document.getElementById('filelist').innerHTML = '<div id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></div>';
                });
                uploader.start();
            },

            UploadProgress: function(up, file) {
                document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
            },
            FileUploaded: function(up, file) {
                document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML += '<input type="hidden" name="cv_file" value="' + file.target_name + '"/>';
            },
            Error: function(up, err) {
                document.getElementById('filelist').innerHTML = "\nError #" + err.code + ": " + err.message;
            }
        }
    });

    uploader.init();
    
   
    $('#careers-form').validate({
        submitHandler: function() {
            var data = $('#careers-form').serialize();
            var object = {
                'success': function (response){
                    $('#careers-form .form-response').removeClass('alert-success');
                    $('#careers-form .form-response').removeClass('alert-danger');

                    if(response['status']=='ok'){
                        form_submit_response('#careers-form', 'success', response['message']);
                         setTimeout(function () {
                            window.location.reload();
                          }, 2000);
                    }else{
                        form_submit_response('#careers-form', 'error', response['message']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}
