var xhr, scheduled = false, _throttleDelay = 200, $body, $modal, $secondaryModal,$window;
var timeout = false;
var delta = 200;
var subnavOrigin = 0;
var body = document.querySelector("body");

$(function(){	
	"use strict";
	
    $.cookieBar({
        element: 'body',
        zindex: '999999',
        message: 'This website uses cookies to ensure that we can provide you with the best user experience. By using this website you agree to our use of cookies.',
        acceptText: 'Ok',
        //policyText: 'MORE',
        policyURL: URL+'privacy-policy/',
        domain: 'https://www.modernacorp.com/',
        referrer: 'https://www.modernacorp.com/'
    });

    if($('#cookie-bar').length>0){
       $('.cb-enable').addClass('cb-btns') ;
       $('.cb-policy').addClass('cb-btns') ;
       $('.cb-btns').wrapAll('<div class="groupedContent"></div>');
             
    }
    $(".fancybox").fancybox();
    
	callbacks();
	
});


$(document).ready(function () {
	"use strict";
    $(document).on('click', 'a.filter', function (e) {
        var key = $(this).data('filterName'),
            target = $(this).data('target'),
            value = $(this).data('value'),
            label = $(this).text(),
            multiple = $(this).data('multiple');

        if($(this).closest('li').hasClass('active') || $(this).hasClass('active')){
            return false;
        }

        if (multiple == true) {

            if ($(this).parent().hasClass('active')) {
                // it's already checked. Uncheck it please
                $(this).parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"][value="' + value + '"]').remove();

            } else if (value == '') {
                $('a.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"]').remove();
            } else {
                // Not checked
                $(this).parent().addClass('active');
                $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '[]" value="' + value + '"/>');
            }
        } else {
            $('.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
            $(this).parent().addClass('active');
            var t = $(this).closest('.filter-group').find('.current-filter-value');
            if(value!==''){
                t.html(label);
            }else if(value===''){
                t.html(t.data('default'));
            }

            $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        }


        filter(target);

        e.preventDefault();
    });



	if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
		$('body').addClass('touch');
	} else {
		$('body').addClass('no-touch');		
	}

	
    var keydown_timeout;
    $body = $('body');
    $window = $(window);

    $body.append('<div id="modalSecondary" class="modal fade"><div class="modal-content"></div></div>');
    $body.append('<div id="modal" class="modal fade"><div class="modal-content"></div></div>');

    $modal = $('#modal');
    $secondaryModal = $('#modalSecondary');

    $(document).on('ifToggled','.toggle-select-all', function(event){
        var target = $(this).data('toggleSelectAllTarget');
        $(target).each(function () {
            $(this).iCheck('toggle');
        });
    });

  $(document).on("click", '.pagination a', function ($e) {
        var target = 'news';   
        var page = $(this).data('page');    
        pagination(target,page);
        return false;
    });

    $(document).on("click", ".load-more", function () {
        var target = $(this).data('target');
        more(target);
        return false;
    });

    $(document).on('click','a[data-target="#search-collapsible"]',function(){
        registerGAEvent('button','click','search');
        scroll2position(0);
        setTimeout(function(){
            $('input[name="search_param"]').focus();
        },500);
    });



      $(document).on('click', '.scroll-to-element', function (e) {
        scroll2element($(this).attr('href'));
        e.preventDefault();
    });



    $(document).on('click', ".ajax-modal", function (e) {
		
        try{
            if(typeof ($(this).attr("href"))!=="undefined"){
				openAjaxModal($(this).attr('href'), $('#'+ $(this).data('modal')));				
			}else{
				openAjaxModal($(this).data('href'), $('#'+ $(this).data('modal')));
			}
			e.preventDefault();
        }catch(Error){
            console.log(Error);
        }

        return false;
    });

   

    $(document).on('click', '[data-action="submit"]', function () {
        var target = $(this).data('target');
        $(target).submit();
        return false;
    });
	
   
   
});

function openAjaxModal(href,$mm){

     $m = $mm || $modal;

    $m.removeData('bs.modal');
    $m.empty();
    $m.load(href,function(){
        $m.modal('show');
        //skinFormElements('#'+$m.attr('id'));
        callbacks('#'+$m.attr('id'));
    });

}

function equalizeHeight(target){
	$('.'+ target).each(function(){
		$('.'+ target).addClass('autoHeight');
		$('.'+ target).css('min-height','auto');
		if($('.'+ target).parent().hasClass('card')){
			$('.'+ target).css('height','auto');
		}
	});
	
    var min_height = 0;
    setTimeout(function(){
        $('.'+ target).each(function(){
            if($(this).innerHeight() > min_height){
                min_height = $(this).innerHeight();
            }
        });
        if(min_height>0){
            $('.'+ target).css('min-height',min_height);
			if($('.'+ target).parent().hasClass('card')){
				$('.'+ target).css('height',min_height);
			}
        }
		$('.'+ target).each(function(){			 
			$('.'+ target).removeClass('autoHeight');
		});
		
    },500);
	
}


function form_submit_response(target, type, message, r) {
    "use strict";
    var redirect = r || false;
    //alert(message);
    
    
    var $timeout = 0;
    
    if($('body').hasClass('modal-open') ){
        $('.modal').modal('hide');
        $timeout = 300; 
    }
    setTimeout(function(){
        var _container = target || '';
        if(type == 'success'){
            var icon = '<i class="fa fa-check-circle" aria-hidden="true"></i>'; 
        }else{
            var icon = '<i class="fa fa-exclamation-circle" aria-hidden="true"></i>';   
        }
        var content = '<div class="alert alert-'+type+'"><span>'+icon+' '+message+'</span></div>'
        $('#responseModal .modal-body').html(content);  
        $('body').addClass('dark-backdrop');
        $('#responseModal').modal('show');
        $('#responseModal').on('hidden.bs.modal', function () {
            $('#responseModal .modal-body').empty();
            $('body').removeClass('dark-backdrop');
        });
    },$timeout);
    
    setTimeout(function(){
        $('#responseModal').modal('hide');
    },3000);
    
    
    if (redirect) {
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }
}




function triggerClick(target) {
	$('.'+target).trigger('click');
}

function callbacks(c) {
    var container = c || '';
    ajaxContent(container + ' .ajax-content');

    $(container + ' .callbacks').each(function () {
        if(!$(this).hasClass('callbacks-executed')){
            if(container !=='' || !$(this).hasClass('callbacks-executed')){
            var c = $(this).data();
            for (var i in c) {
                if (i.indexOf('fn') == 0 && c[i] != '') {
                    var fn = c[i];
                    var params = c[ 'params' + i ] || null;

                    $(this).addClass('callbacks-executed');

                    try {
                        if (c[ 'after' + i ] && typeof(window[c[ 'after' + i ]]) === 'function') {

                            var callbackAfter = c[ 'after' + i ];
                            var callbackAfterParams = c[ 'after' + i + 'Params' ] || null;

                            window[ fn ](params, function(){
                                window[callbackAfter](callbackAfterParams);
                            });
                        } else {
                            window[ fn ](params);
                        }
                    } catch (Error) {
                        console.log(Error + ' '+fn);
                    }
                }
            }
        }
        }
    });
}

function ScrollHandler(e) {
    if (!scheduled) {
        scheduled = true;
        setTimeout(function () {
            if ($(window).scrollTop() + $(window).height() > $(document).height() - 300) {
                more(e.data.target);
            }
            scheduled = false;
        }, _throttleDelay);
    }
}


function scroll2position(pos,t) {
    $('html, body').animate({scrollTop: pos}, t || 500);
}



function pagination(target,page){
    
    var all_pages = Number($('form[name="'+target+'-nav"] input[name="num_pages"]').val())-1;   
    var currentpage = Number($('form[name="'+target+'-nav"] input[name="page"]').val());    
    if(page=='prev'){
        page = currentpage-1;
    }else if(page=='next'){
        page = currentpage+1;
    }   
    console.log(page);

     console.log(all_pages);
    
    if(page==-1 || all_pages < page){
        return false;
    }

    $('form[name="'+target+'-nav"] input[name="page"]').val(page);
    
    $.ajax({
        data:$('form[name="'+target+'-nav"]').serialize(),
        dataType:'html',
        success:function(data){
            $newItems = $.parseHTML( data );
            $('#'+target+'-list').empty();          
            $.each( $newItems, function( i, el ) {
                $(el).data('duration', i ).appendTo('#'+target+'-list');            
            });         
            
        }
    });
    if(all_pages==page){
        $('.next').addClass('hidden-button');
    }else{
        $('.next').removeClass('hidden-button');
    }
    if(page==0){
        $('.prev').addClass('hidden-button');
    }else{
        $('.prev').removeClass('hidden-button');
    }
    $('.pagination li.active').removeClass('active');
    $('.pagination a[data-page="'+page+'"]').parent().addClass('active');
    $('html,body').animate({scrollTop: $('#news-list').offset().top-100},'slow');   
}




function more(target) {
    if ($('form[name="' + target + '-nav"] ').hasClass('busy')) {
        return false;
    }

    var c = $('form[name="' + target + '-nav"] input[name="page"]').val();
    var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();
    $('form[name="' + target + '-nav"] input[name="page"]').val(Number(c) + 1);
    $('form[name="' + target + '-nav"]').addClass('busy');
    $('.load-more[data-target="'+target+'"]').hide();
    $.ajax({data: $('form[name="' + target + '-nav"]').serialize(), dataType: 'html', success: function (result) {
        $('form[name="' + target + '-nav"]').removeClass('busy');

        var $result = $('<div>' + result + '</div>');

        var num_items = $result.find('.item').length;
        if ( num_items > 0) {
            $result.find('.item').hide();
            $('.' + target + '-list').append($result.html());
            $('.' + target + '-list .item').fadeIn('fast');

            callbacks('.' + target + '-list');

            $('.load-more[data-target="'+target+'"]').show();
            if(num_items<offset){
                $(document).off('scroll', ScrollHandler);
            }
        } else {
            $('.load-more[data-target="'+target+'"]').hide();
            $(document).off('scroll', ScrollHandler);
        }

        $('.' + target + '-list .way-point-animate').waypoint(function(direction) {
            $(this.element).addClass('shown');
        }, {
            offset: '95%'
        });

        if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }
    }
    });
}

var ajax_contents = [];
function ajaxContent(c){
    $modal = $('#modal');
    var contents = c || '.ajax-content';
    $(contents).each(function () {
        ajax_contents.push(
            {
                'target': $(this),
                'content':$(this).data('ajaxContent')
            }
        );
    });
    loadAjaxContent();
}

function loadAjaxContent(){
    if(ajax_contents.length > 0){
        var target  = ajax_contents[0].target,
            content = ajax_contents[0].content;
            
        /*if(target.hasClass('ajax-load-initiated')) {
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
            return false;
        }
*/
        target.addClass('ajax-load-initiated');

        target.load(content,function(){
            var uniq = (Math.random() + '').replace('0.', '');
            target.addClass('ajax-loaded-'+uniq);
            callbacks('.ajax-loaded-'+uniq);
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
        });
    }else{
        $('.ajax-load-initiated').removeClass('ajax-load-initiated');
    }
}



function filter() {
    var target = arguments[0];
    var after = arguments[1];
    $('form[name="' + target + '-nav"] input[name="page"]').val(0);
    var request_data = $('form[name="' + target + '-nav"]').serializeArray(),
        excludes = ['module', 'action', 'active_for', 'language_id', 'data_type', 'country_code'],
        includes = ['cid[]','sort_term','sort_way'],
        elements = {};

    var initialHeight = $('.' + target + '-list').innerHeight();
    $('.' + target + '-list').height(initialHeight).css({'position':'relative','min-height':100,'overflow':'hidden'}).html('<div class="loading la-animate"></div>');

    $.ajax({data: request_data, dataType: 'html', success: function (data) {
        $('.' + target + '-list').css({'overflow':'visible'});
        $('.' + target + '-list').html(data).height('auto');



        var num_items = $('.' + target + '-list .item').length;
        var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();

        if (after) {
            after.call();
        }else if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }

        $(document).off('scroll', ScrollHandler);
        if($('.' + target + '-list').hasClass('infinite-scroll') &&  offset != 0 && num_items == offset) {
            $(document).on('scroll', {target: target}, ScrollHandler);


            //$('.loading-more').remove();
            //$('.' + target + '-list').append('<div class="loading-more">Scroll to load more<br><i class="fa fa-arrow-down"></i></div>');
            //$('.loading-more').fadeIn('fast');
            //setTimeout(function(){
            //    $('.loading-more').fadeOut('slow',function(){
            //        $(this).remove();
            //    });
            //},5000);

        }

        if(num_items == offset){
            $('.load-more[data-target="'+target+'"]').show();
            if($('.' + target + '-list').height() < $(window).height() - $('header').height()){
                more(target);
            }
        }else{
            $('.load-more[data-target="'+target+'"]').hide();
        }



    }});

    resize();
}
 

function canvasWave(){

  var wrapper = $('.canvas-wrapper');
  var target = wrapper.data('target');


  var stageWidth;
  var stageHeight;
  var context = $("#"+target)[0].getContext('2d');
  var objectList;
  var timeout;
  
  var prms = {
    fps:30,
    objectDistance: 20,
    objectWidth: 2,
    objectRotationV: Math.PI * .05,
    objectRadius: 3
  }
  
  $(window).resize(reset);
   setTimeout(function(){
             reset();
    },500);
   

  
  function reset(){
    
    stageWidth = $('.canvas-wrapper').width();
    stageHeight = $('.canvas-wrapper').height();


    
    $("#"+target).attr("width",stageWidth);
    $("#"+target).attr("height",stageHeight);

    objectList = [];
    
    var x = -prms.objectDistance * .5;
    var y;
    
    while(x < stageWidth + prms.objectDistance * .5){
      y = -prms.objectDistance * .5;
      while(y < stageHeight + prms.objectDistance * .5){
        var xDistance = x-stageWidth*.5;
        var yDistance = y - stageHeight*.5;
        var distance = Math.sqrt(xDistance*xDistance + yDistance*yDistance);
        var circleRotation = Math.PI*.005*distance;
        objectList.push(
          new Circle(
            x,
            y, 
            circleRotation // _rotationV
          )
        );
        y += prms.objectDistance;
      }
      x += prms.objectDistance;
    }
    
    if(timeout){
      clearTimeout(timeout);
    }
    timeout = setTimeout(onEnterFrame, 1000/prms.fps);
  }
  
  function Circle(_x, _y, _rotation){
    this.x = _x;
    this.y = _y;
    this.vx = 0;
    this.vy = 0;
    this.ax = 0;
    this.ay = 0;
    this.rotation = _rotation;
    this.cx = _x;
    this.cy = _y;
    
    
  }
  Circle.prototype.width = prms.objectWidth;
  Circle.prototype.rotationV = prms.objectRotationV;
  Circle.prototype.radius = prms.objectRadius;
  Circle.prototype.tick = function(){
    this.rotation += this.rotationV;
    this.x = this.radius * Math.cos(this.rotation) + this.cx;
    this.y = this.radius * Math.sin(this.rotation) + this.cy;
    this.width = (1 + Math.cos(this.rotation))*Circle.prototype.width;
  }
  Circle.prototype.draw = function(_context){
    //console.log(this);
   // console.log(this.x - this.width*.5, this.y - this.height*.5, this.width, this.height);
    _context.beginPath();
    _context.arc(this.x, this.y, this.width * .5, 0, Math.PI*2, false);
    _context.fill();
    _context.closePath();
  }
  
  function onEnterFrame(){
    
    var len = objectList.length;
    var object;
    
    
    while(len > 0){
      len -= 1;
      object = objectList[len];
      object.tick();
    }

    //context.fillStyle = 'rgba(0,0,0,.2)';
    //context.fillRect(0, 0, stageWidth, stageHeight);
    context.clearRect(0, 0, stageWidth, stageHeight);
    context.fillStyle = '#ccffff';
    len = objectList.length;
    while(len > 0){
      len -= 1;
      object = objectList[len];
      object.draw(context);
    }
    
    timeout = setTimeout(onEnterFrame, 1000/prms.fps);
  }
      
}


function contactSlider(){
   "use strict";
        function e() {
            a.on("init", function() { i.eq(0).addClass("active"), o.eq(0).addClass("active") }), a.slick(s), a.on("beforeChange", function(e, n, r, a) { i.removeClass("active"), o.removeClass("active"), $(".contact__office[data-office=" + a + "]").addClass("active"), $(".contact__list[data-contact-list=" + a + "]").addClass("active") }), $(".contact__stick-menu-item").on("click", function(e) {
                var n = $(e.currentTarget),
                    i = n.parents(".slick-slide").data("slick-index");
                a.slick("slickGoTo", i)
            })
        }
        var n = $(".map"),
            i = $(".contact__office"),
            r = $(".contact__address"),
            o = $(".contact__list"),
            a = $(".contact__stick-menu-list"),
            s = { dots: !1, arrows: !1, infinite: !1, speed: 300, autoplay: !1, slidesToShow: 1, slidesToScroll: 1, centerMode: !0, variableWidth: !0, swipe: !0 };
        $(".contact__name").on("click", function(e) {
            if (window.innerWidth > 768) {
                var n = $(e.currentTarget);
                if (!n.parent().hasClass("open")) {
                    var a = n.parent().data("office");
                    i.removeClass("open"), r.slideUp(300), o.removeClass("active"), t(".contact__list[data-contact-list=" + a + "]").addClass("active"), n.parent().addClass("open"), n.parent().find(".contact__address").slideDown(300)
                }
            }
        }), window.innerWidth > 768 ? (i.eq(0).addClass("open"), i.eq(0).find(".contact__address").slideDown(300), o.eq(0).addClass("active")) : a.length && e(), n.length && window.innerWidth > 768 && ($(window).scrollTop() > 530 ? $("body").addClass("header--white") : $("body").removeClass("header--white")), $(window).on("resize", function() { window.innerWidth < 769 ? a.hasClass("slick-initialized") || (i.removeClass("open"), r.attr("style", ""), o.removeClass("active"), e()) : a.hasClass("slick-initialized") && (i.eq(0).addClass("open"), i.eq(0).find(".contact__address").slideDown(300), o.eq(0).addClass("active"), a.slick("destroy")) }), $(window).on("scroll", function() { n.length && (window.innerWidth > 768 && $(window).scrollTop() > 530 ? $("body").addClass("header--white") : $("body").removeClass("header--white")) })
   
}
        


function valuesSlider(){
   "use strict";
        var e = $(".values__info-inner"),
            i = $("[data-values-slider]"),
            r = window.innerWidth < 769 || !1,
            o = $(".values__title"),
            a = { dots: !0, arrows: !1, infinite: !1, speed: 300, autoplay: !1, slidesToShow: 1, slidesToScroll: 1, appendDots: e.parent().find(".values__dots"), swipe: !0, adaptiveHeight: !0 },
            s = { dots: !1, arrows: !1, slidesToShow: 1, slidesToScroll: 1, fade: !0, autoplay: !1 };
        $(".values").delegate(".values__title", "click", function(e) {
            if (!r) {
                var n = $(e.currentTarget);
                if (!n.hasClass("active")) {
                    o.removeClass("active"), n.addClass("active");
                    var a = n.data("value-block");
                    $(".values__description").removeClass("active"), $(".values__image").removeClass("active"), $(".values__image h2").removeClass("animate"),$(".values__description-inner").removeClass("animate"), $(".values__description[data-value-block=" + a + "]").addClass("active"), $(".values__image[data-value-block=" + a + "]").addClass("active"), setTimeout(function() { $(".values__image[data-value-block=" + a + "]").find("h2").addClass("animate"), $(".values__description[data-value-block=" + a + "]").find(".values__description-inner").addClass("animate") }, 100), i.hasClass("slick-initialized") && i.slick("slickGoTo", a)
                }
            }
        }), $(".values__image").eq(0).addClass("active"), $(".values__image").eq(0).find("h2").addClass("animate"), r ? e.length && (e.slick(a), e.on("beforeChange", function(e, n, i, r) { $(".values__image").removeClass("active"), $(".values__image").find("h2").removeClass("animate"), $(".values__image[data-value-block=" + r + "]").addClass("active"), setTimeout(function() { $(".values__image[data-value-block=" + r + "]").find("h2").addClass("animate") }, 100) })) : ($(".values__description").eq(0).addClass("active"), $(".values__description").eq(0).find(".values__description-inner").addClass("animate"), o.eq(0).addClass("active"), i.length && i.slick(s)), $(window).on("resize", function() { window.innerWidth < 769 ? (e.hasClass("slick-initialized") || e.slick(a), e.on("beforeChange", function(e, n, i, r) { $(".values__image").removeClass("active"), $(".values__image").find("h2").removeClass("animate"), $(".values__image[data-value-block=" + r + "]").addClass("active"), $(".values__image[data-value-block=" + r + "]").find("h2").addClass("animate") }), i.hasClass("slick-initialized") && i.slick("destroy"), r = !0) : (e.hasClass("slick-initialized") && e.slick("destroy"), i.hasClass("slick-initialized") || i.slick(s), r = !1) })
    
}


function navSlider(){
    "use strict";
        var e = $(".menu--main .menu__item--current"),
            i = $(".menu--secondary > .menu__list");
        if (e) {
            var r = e.parent("ul");
            r && r.children().clone().appendTo(".menu--secondary > .menu__list")
        }
        var o = { dots: !1, arrows: !1, infinite: !1, speed: 300, autoplay: !1, slidesToShow: 1, centerMode: !0, variableWidth: !0, swipe: !0 };
        if (i.length && window.innerWidth < 1025) {
            i.slick(o);
            var a = $(".menu--secondary .menu__item--current");
            if (a.length) {
                var s = a.parents(".slick-slide").data("slick-index");
                i.slick("slickGoTo", s)
            }
        }
        $(window).on("resize", function() { window.innerWidth < 1025 ? i.hasClass("slick-initialized") || i.slick(o) : i.hasClass("slick-initialized") && i.slick("destroy") })
    
   
}
function heroSlider(){
     
       
        var i = $("[data-slider]"),
            r = $(".hero-slider__loader");


        $(".hero-slider__title").each(function(e, n) {
             var i = $.trim($(n).html());
            $(n).html(i).lettering("words");
        });

        $('.hero-slider__title span').each(function(e, n) { 
            $(n).wrap('<span class="hero-slider__title-word"></span>') 
        });
       
       
        i.length && i.each(function(e, n) { 
            $(n).on("init", function() { 
            $(".hero").addClass("loaded"), 
            setTimeout(function() { $(".slick-slide").length > 1 && r.addClass("in-progress"), $(".hero-slider__title").eq(0).find(".hero-slider__title-word").each(function(e, n) { 
                setTimeout(function() { $(n).addClass("animated") }, 100 * e) }) }, 1700) }), $(n).slick({ fade: !0, speed: 1500, dots: !0, arrows: !1, appendDots: $(n).parent().find(".hero-slider__dots"), autoplay: !0, autoplaySpeed: 9000, pauseOnHover: !1, adaptiveHeight: !0 }) }).on("beforeChange", function() { r.removeClass("in-progress") }).on("afterChange", function(e, n) { r.addClass("in-progress"), 0 === $(".slider-one-title").length && ($(".hero-slider__title-word").removeClass("animated"), $("[data-slick-index=" + n.currentSlide + "]").find(".hero-slider__title-word").each(function(e, n) { setTimeout(function() { $(n).addClass("animated") }, 100 * e) })) })
   
}

function bannersSlider(){
     "use strict";
        var e = $("[data-related-banners]"),
            i = $("[data-related-stories]");
        e.length && e.slick({ dots: !0, arrows: !1, prevArrow: '<button type="button" class="slick-prev"></button>', nextArrow: '<button type="button" class="slick-next"></button>', infinite: !0, appendDots: e.parent().find(".related-banners__dots"), slidesToShow: 1, slidesToScroll: 1, mobileFirst: !0, responsive: [{ breakpoint: 767, settings: { slidesToShow: 2, arrows: !0 } }, { breakpoint: 1024, settings: { slidesToShow: 3, arrows: !0 } }] }), i.length && i.slick({ dots: !0, arrows: !1, prevArrow: '<button type="button" class="slick-prev"></button>', nextArrow: '<button type="button" class="slick-next"></button>', infinite: !0, appendDots: i.parent().find(".stories__dots"), slidesToShow: 1, slidesToScroll: 1, mobileFirst: !0, responsive: [{ breakpoint: 767, settings: { slidesToShow: 2, arrows: !0 } }] })
} 

function newsSlider(){
     "use strict";

     $('.related-gallery__slider').slick({
          dots: !1, arrows: !0, prevArrow: '<button type="button" class="slick-prev"></button>', 
            nextArrow: '<button type="button" class="slick-next"></button>', infinite: !0, 
            slidesToShow: 1, slidesToScroll: 1, mobileFirst: !0, 
            responsive: [{ breakpoint: 767, settings: { slidesToShow: 2, arrows: !0 } }, 
            { breakpoint: 1024, settings: { slidesToShow: 3, arrows: !0 } }]
        });
       


} 


function figureSlider(){
     "use strict";
        var e = $("[data-figure]");
        var fg =   e.attr("data-figure-length");
        if(fg==null || fg==undefined){
            fg =3;
        }
        e.length && e.slick({ speed: 1500, dots: !0, arrows: !1, appendDots: e.parent().find(".figure__dots"), autoplay: !1, pauseOnHover: !1, infinite: !1, autoplaySpeed: 2500, slidesToScroll: 1, slidesToShow: 1, mobileFirst: !0, responsive: [{ breakpoint: 768, settings: { slidesToShow: 2 } }, { breakpoint: 1100, settings: { slidesToShow: fg } }] })
   
}

function registerGAEvent(title,content){
    dataLayer.push({
        'content': content,
        'event': title
    });
};



function initMap(){

    var center = [23.70332, 37.9908997];

    var initial_latitude = Number( $( ".marker-wrapper:first" ).data( "latitude" ));
    var  initial_longtitude = Number( $( ".marker-wrapper:first" ).data( "longtitude" ));

    if($( ".marker-wrapper" ).length>0){
        center = ol.proj.fromLonLat([initial_longtitude, initial_latitude]);
    }else{
        center = ol.proj.fromLonLat(center);
    }

    var view =  new ol.View({
        center: center,
        zoom: 16
    });

     var map = new ol.Map({
        layers: [
          new ol.layer.Tile({
              source: new ol.source.OSM()
          }),
        ],
        target: 'contact-map',
        view:view
    });

    if($( ".marker-wrapper" ).length>0){
         var marker_points = [];
         $( ".marker-wrapper" ).each(function(index) {
              var  latitude = Number($( this ).data( "latitude" ));
              var  longtitude = Number($( this ).data( "longtitude" ));
              var element = this;

              var pos = ol.proj.transform([longtitude, latitude ], 'EPSG:4326', 'EPSG:3857');
             
              var marker = new ol.Overlay({
                 element: element,
                 position: pos,
                 positioning: 'center-center'
             });
             map.addOverlay(marker);

         });

        
     }

    setTimeout(function(){
         map.updateSize();
    }, 500);
   
   
    

}