var History = {
	init: function(){
		var history_dates = $(".history-dates .carousel-content");			
		history_dates.slick({
			infinite: false,
			slidesToShow: 5,
			swipeToSlide:true,
			speed: 500,			
			arrows:false,
			dots:false	,
			focusOnSelect: true,
			draggable : false,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 4,
					draggable : true,
					focusOnSelect: true,
					swipeToSlide:true
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					  slidesToShow: 3,
					  draggable : true,
					  focusOnSelect: true
				  }
				},
				{
				  breakpoint: 767,
				  settings: {
					  slidesToShow: 2,
					  draggable : true,
					  focusOnSelect: true
				  }
				},
			]
		});	
		
		var history_details = $(".history-dates .slider-details");		
		history_details.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			 asNavFor: '.history-dates .carousel-content'
		});
		
		$(".history-dates .carousel-content .slick-slide").click(function(){
			var $this = $(this);
			var $index = $this.data('slick-index');
			history_dates.slick('slickGoTo', $index , false);	
			history_details.slick('slickGoTo', $index , false);	
		}); 	
		
		history_details.on('afterChange', function(slick, currentSlide){
			var $index = $(".history-dates .slider-details .slick-current").data('slick-index');
			$('.history-dates .carousel-content .slick-slide .active-date').removeClass('active-date');
		  	$('.history-dates .carousel-content .slick-slide[data-slick-index="'+$index+'"] .item').addClass('active-date');
		});
	}	
};